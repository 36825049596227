<template>
  <div class="portfolio-page-content">
    <div class="page-info">
      <Info
        :page-key="'advanced_generate_portfolio'"
        :button-size="'lg'"
        :video-link="'https://player.vimeo.com/video/936656206?badge=1&autopause=0&player_id=1&app_id=58479&byline=0&controls=1&transcript=0&portrait=0&autoplay=0&title=1'"
      />
    </div>
    <Main>
      <div class="generate-portfolio-main">
        <a-row>
          <a-col :xs="24">
            <p>{{ $t("generate_portfolio_advanced.text") }}</p>
          </a-col>
        </a-row>
        <a-row gutter="8">
          <a-col :xs="24" :lg="16" style="margin-top: 8px;">
            <a-select
              mode="select"
              :value="selectedLimit?.id"
              :placeholder="$t('portfolio_limits.saved_limits_placeholder')"
              @select="handleSelectLimit"
              @clear="handleSelectClear"
              style="width: 100%; height: 42px"
              class="native-select"
              :loading="portfolioLimitsStore.loading"
              allowClear
            >
            <a-select-option
                v-for="saved_limit in portfolioStore.savedLimits"
                :key="saved_limit.id"
                :value="saved_limit.id"
                :title="saved_limit.name"
              >
                {{ saved_limit.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :xs="24" :lg="8" class="switcher-wrapper">
            <sdButton
              class="switch-btn"
              :class="openSaved && 'icon-white'"
              :type="openSaved ? 'primary' : 'secondary'"
              @click="openSaved = true"
            >
              {{ $t('portfolio_limits.open_limit') }}
            </sdButton>
            <sdButton
              class="switch-btn"
              :class="!openSaved && 'icon-white'"
              :type="!openSaved ? 'primary' : 'secondary'"
              @click="openSaved = false"
              v-if="!authStore.restrictions.includes(Restrictions['RenameDeleteLimits'])"
            >
              {{ $t('portfolio_limits.rename_limit') }}
            </sdButton>
            <a-popconfirm
              :title="$t('portfolio_limits.confirm_delete')"
              :ok-text="$t('search_funds.select_family_columns.yes')"
              placement="left"
              :cancel-text="$t('search_funds.select_family_columns.no')"
              @confirm="deleteLimit"
              v-if="!authStore.restrictions.includes(Restrictions['RenameDeleteLimits'])"
            >
              <sdButton
                class="switch-btn icon-white"
                type="secondary"
                :loading="portfolioStore.loadingDeleteLimit"
              >
                {{ $t('portfolio_limits.delete_limit') }}
              </sdButton>
            </a-popconfirm>
          </a-col> 
        </a-row>
        <template v-if="openSaved">
          <a-row class="mt-3">
            <a-col :sm="24">
              <limit-risk />
              <div class="mt-5">
                <limits-component :isCollapsed="1" />
              </div>
            </a-col>
          </a-row>
          <a-row align="left" class="button-placement">
            <a-col :xs="24" class="mt-5">
              <sdButton
                size="small"
                class="generate-button"
                type="secondary"
                @click="generatePortfolio"
                :loading="portfolioLimitsStore.loading"
              >
                {{ $t("generic.buttons.generate_portfolio") }}
              </sdButton>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-row>
            <div
              class="flex mt-4"
              style="width: 75%;"
            >
              <a-input v-model:value="newName" />
              <sdButton
                type="primary"
                @click="renamePortfolio()"
                :loading="portfolioStore.loadingRenameLimit"
                :disabled="!selectedLimit"
              >
                {{ $t('portfolio_limits.save') }}
              </sdButton>
            </div>
          </a-row>
        </template>
      </div>
    </Main>
  </div>
  <a-modal
    v-model:visible="showOptionModal"
    type="primary"
    :footer="null"
    class="option-modal"
  >
    <div style="padding: 0 2.5rem;">
      <label class="mt-4" style="font-size: 16px;">
        {{ $t('portfolio_limits.save_limit') }}
      </label>
      <div style="display: flex; align-items: center;">
        <a-input
          v-model:value="portfolioName"
          :placeholder="$t('portfolio_limits.input_placeholder')"
        />
        <sdButton
          type="primary"
          style="height: 38px;"
          @click="handleSaveLimit"
          :loading="portfolioStore.loadingSaveLimit"
        >
          {{ $t("portfolio_limits.save") }}
        </sdButton>
      </div>
      <sdButton
        type="secondary"
        @click="handleOk"
        style="width: 100%;"
        class="mt-4"
        :loading="loadingOverview"
      >
      {{ $t("generic.buttons.show_portfolio") }}
      </sdButton>
    </div>
  </a-modal>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { Main } from "../styled";
import LimitsComponent from "@/components/wiseral/filters/limits-component";
import LimitRisk from "@/components/wiseral/filters/limit-risk";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import Info from "@/components/infoModal/index.vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import Restrictions from "@/config/restriction/restrict_functions";

const { t } = useI18n();

const portfolioLimitsStore = usePortfolioLimitStore();
const portfolioStore = usePortfolioStore();
const authStore = useAuthStore();
const router = useRouter();

const limitTab = ref(1);
const showOptionModal = ref(false);
const generatedPortfolio = ref(null);
const portfolioName = ref('');
const loadingOverview = ref(false);
const openSaved = ref(true);
const selectedLimit = ref(null);
const newName = ref('');

onMounted(async () => {
  portfolioLimitsStore.invest_amount =
    authStore.config["default_investment_amount"];
  await portfolioLimitsStore.getDefaultCategories();
  await portfolioStore.fetchLimits();
});

const generatePortfolio = async () => {
  try {
    let limitJson = "";
    if (selectedLimit.value?.limits_json) {
        limitJson = JSON.stringify(JSON.parse(selectedLimit.value.limits_json));
    }
    const isLimitToBeSaved = !selectedLimit.value || limitJson !== getLimitsJson();
    const result = await portfolioLimitsStore.generateAdvancedPortfolio();
    if (result) {
      portfolioStore.portfolio_type = 1;
      portfolioStore.original_portfolio_name = null;
      portfolioStore.portfolio_id = null;
      portfolioStore.last_update_date = null;
      portfolioStore.selectedPortfolio = result;
      generatedPortfolio.value = result;
      if (isLimitToBeSaved && !authStore.restrictions.includes(Restrictions['RenameDeleteLimits'])) {
        showOptionModal.value = true;
      } else {
        handleOk();
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const getLimitsJson = () => {
  const risks = Object.values(authStore.config["risk_levels"]);
  let default_risk = 0;
  if (risks.length) {
    default_risk = risks[Math.ceil(risks.length / 2) - 1];
  }
  const default_weight = authStore.config["max_weight"];
  const data = {
    categories: portfolioLimitsStore.categories,
    max_weight:
      portfolioLimitsStore.max_weight !== null ? portfolioLimitsStore.max_weight : default_weight,
    apply_risk: portfolioLimitsStore.apply_risk || default_risk,
    delete_weight_zero: true,
    only_my_bank: portfolioLimitsStore.only_my_bank,
    invest_amount: portfolioLimitsStore.invest_amount,
    compositions: portfolioLimitsStore.compositions.filter(
      (e) =>
        e.composition !== null ||
        e.operand !== null ||
        e.percentage !== null
    ),
    weights: portfolioLimitsStore.weights.filter(
      (e) =>
        e.weight !== null || e.operand !== null || e.percentage !== null
    ),
  };
  return JSON.stringify(data);
};

const handleOk = () => {
  if (generatedPortfolio.value) {
    loadingOverview.value = true;
    portfolioStore.selectedPortfolio = generatedPortfolio.value;
    router.push({
      name: "portfolio-overview-ids",
      params: { ids: portfolioStore.selectedPortfolioIds.join(",") },
    });
  }
};

const handleSaveLimit = async () => {
  if (portfolioName.value) {
    await portfolioStore.saveLimit(portfolioName.value, getLimitsJson());
  }
};

const handleSelectLimit = async (e) => {
  const openedLimit = portfolioStore.savedLimits?.find(item => item.id === e);
  if (openedLimit) {
    selectedLimit.value = openedLimit;
    if (openSaved.value) {
      const limitData = JSON.parse(openedLimit.limits_json);
      portfolioLimitsStore.categories = limitData?.categories ?? [];
      portfolioLimitsStore.max_weight = limitData?.max_weight ?? null;
      portfolioLimitsStore.apply_risk = limitData?.apply_risk ?? null;
      portfolioLimitsStore.delete_weight_zero = true;
      portfolioLimitsStore.only_my_bank = limitData?.only_my_bank ?? false;
      portfolioLimitsStore.invest_amount = limitData?.invest_amount ?? 0;
      portfolioLimitsStore.weights = limitData?.weights ?? [];
      portfolioLimitsStore.compositions = limitData?.compositions ?? [];
      limitTab.value = 0;
    } else {
      newName.value = selectedLimit.value?.name ?? '';
    }
  }
};

const handleSelectClear = () => {
  selectedLimit.value = null;
};

const renamePortfolio = async () => {
  await portfolioStore.renameLimit(selectedLimit.value?.name, newName.value);
};

const deleteLimit = async () => {
  if (selectedLimit.value) {
    await portfolioStore.deleteLimit(selectedLimit.value.id).then(() => {
      selectedLimit.value = null;
      newName.value = '';
    });
  } else {
    message.warning(t('portfolio_limits.select_any_limit'))
  }
};
</script>

<style scoped lang="scss">
.portfolio-page-content {
  padding: 0 5rem;
  position: relative;
  .page-info {
    position: absolute;
    left: 3.5rem;
    top: -1rem;
    z-index: 100;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio-page-content {
    padding: 0 1rem;
    .page-info {
      left: 0.5rem;
      top: -1.5rem;
    }
  }
}

.button-placement {
  margin-bottom: 20px;
}
.generate-button {
  border-radius: 100px !important;
  width: 12rem;
}
:deep(.ant-page-header) {
  padding-bottom: 4px;
  padding-left: 0px;
  font-size: 16px;
}
.limit-tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
  font-size: 14px;
  width: 75%;
  border: 1px solid;
  & .limit-tab-item {
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: #004851;
      color: white;
    }
    &:hover {
      background-color: #004851;
      color: white;
    }
    & p {
      padding-top: 12px;
      padding-bottom: 12px;
      margin: 0 !important;
    }
  }
}
:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
    background-color: #F2F2F2;
  }
  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  .option-modal {
    width: 75%;
  }  
}
.switcher-wrapper {
  display: flex;
  gap: 4px;
  margin-top: 8px;
  .switch-btn {
    display: flex;
    gap: 4px;
    width: 30%;
    padding: 16px 0 !important;
  }
}
</style>
