<template>
  <!-- <h2 class="subtitle">{{$t('generic.titles.limit_risk')}}</h2> -->
  <label class="mb-2" style="font-size: medium">{{
    $t("generic.titles.limit_risk")
  }}</label>
  <a-row>
    <a-col :xs="24" :lg="14" class="mt-3">
      <a-select
        v-model:value="portfolioLimitStore.categories"
        mode="multiple"
        :placeholder="$t('generate_portfolio_advanced.categories')"
        :tagRender="handleTagRender"
        style="width: 100%"
        @change="updateWeightsAndCompositions"
        :max-tag-count="5"
        :filterOption="customFilter"
      >
        <a-select-option
          v-for="f in categories"
          :key="$t(`search_categories.${f}`, f)"
          :value="f"
          :title="f"
        >
          {{ $t(`search_categories.${f}`, f) }}
        </a-select-option>
      </a-select>
    </a-col>
  </a-row>
  <a-row class="pt-4" :gutter="4">
    <a-col :xs="24" :md="12" :lg="7">
      <label>{{ $t("generate_portfolio_advanced.max_weight") }}</label>
      <percent-input
        class="w-100"
        v-model="portfolioLimitStore.max_weight"
      ></percent-input>
    </a-col>
    <a-col :xs="24" :md="12" :lg="7">
      <label>{{ $t("generate_portfolio_advanced.apply_risk") }}</label>
      <a-select
        v-model:value="portfolioLimitStore.apply_risk"
        style="height: 45px"
        class="native-select mobile-input"
      >
        <a-select-option
          v-for="(i, key) in authStore.config.risk_levels"
          :key="key"
          :value="key"
        >
          {{ $t(`generic.risk_options.${key}`, key) }}
        </a-select-option>
      </a-select>
    </a-col>
  </a-row>
  <a-row class="pt-5">
    <a-col :sm="18" :xs="24">
      <a-checkbox
        class="m-0"
        v-model:checked="portfolioLimitStore.only_my_bank"
      >
        {{ $t("search_funds.auto_filter") }}
      </a-checkbox>
      <!-- <a-checkbox
        style="margin-right: 16px;"
        v-model:checked="portfolioLimitStore.delete_weight_zero"
        v-if="!authStore.restrictions.includes(Restrictions['DeleteWeights'])"
      >
        {{ $t("generate_portfolio_advanced.delete_weight_zero") }}
      </a-checkbox> -->
    </a-col>
  </a-row>
</template>

<script setup>
import { defineProps, computed, onMounted } from "vue";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import { useAuthStore } from "@/stores/AuthStore";
import PercentInput from "@/components/wiseral/PercentInput";
//import Restrictions from "@/config/restriction/restrict_functions";
import { Tooltip } from 'ant-design-vue';
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const portfolioLimitStore = usePortfolioLimitStore();
const authStore = useAuthStore();

const categories = computed(() =>
  authStore.config.searches ? Object.keys(authStore.config.searches) : []
);

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});


const updateWeightsAndCompositions = () => {
  portfolioLimitStore.updateAllWeights();
};

const handleTagRender = (props) => {
  return (
    <Tooltip title={t(`search_category_tooltips.${props.value}`, props.value)}>
      <div class="ant-select-selection-overflow-item" style="opacity: 1;">
        <span class="ant-select-selection-item">
          <span class="ant-select-selection-item-content">{t(`search_categories.${props.value}`, props.value)}</span>
          <span
            class="ant-select-selection-item-remove"
            unselectable="on"
            aria-hidden="true"
            style="user-select: none;"
            onClick={() => portfolioLimitStore.removeCategory(props.value)}
          >
            <span role="img" aria-label="close" class="anticon anticon-close">
              <svg focusable="false" class="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
            </span>
          </span>
        </span>
      </div>
    </Tooltip>
  );
};

const customFilter = (input, option) => {
  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

onMounted(() => {
  const risks = Object.keys(authStore.config["risk_levels"]);
  if (risks.length && !portfolioLimitStore.apply_risk) {
    portfolioLimitStore.apply_risk = risks[Math.ceil(risks.length / 2) - 1];
  }
  if (!portfolioLimitStore.max_weight) {
    portfolioLimitStore.max_weight = authStore.config["max_weight"];
  }
});
</script>

<style scoped lang="scss">
:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
    background-color: #F2F2F2;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
:deep(.ant-select-multiple) {
  .ant-select-selector {
    background-color: #F2F2F2;
  }
}
:deep(.ant-input-number ) {
  background-color: #F2F2F2;
}
</style>
